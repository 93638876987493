import Header from "./header";
import Footer from "./footer";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useAsyncError, useNavigate } from "react-router-dom";
import { FcFilledFilter, FcClearFilters } from "react-icons/fc";
import Header_mobile from "./header_mobile";
import Main from "../Main";

function Customer_list() {
    //Variable part
    const { t } = useTranslation();
    const url = process.env.REACT_APP_BASEURL;
    const navigate = useNavigate();

    //Actions part

    const [all_machine, setall_machine] = useState([]);
    const [all_machine1, setall_machine1] = useState([]);
    const [showfiltermodal, setShowfiltermodal] = useState(false);
    const [messageModal, setMessageModal] = useState("");
    const [textFilter, setTtextFilter] = useState("");
    const [value_type_filter, SetValue_type_filter] = useState();
    const [filter_title, setFiltertitle] = useState("");
    const [filteraction, setFilterAction] = useState(true);
    const [all_report, setall_report] = useState([]);
    const [showModalreport, setshowModalreport] = useState(false);
    const [ifapplyfilter, setIfapplyfilter] = useState(false);
    const [filtervalue, setfilterValue] = useState("");
    const [showfilter, setshowfilter] = useState(false);

    const handleModalShow = (type, title) => {
        if (ifapplyfilter) setall_machine(all_machine1);
        setShowfiltermodal(true);
        SetValue_type_filter(type);
        setFiltertitle(title);
    };

    const handleModalClose = () => {
        setShowfiltermodal(false);
        setMessageModal("");
        setTtextFilter("");
    };
    const handleClose = () => {
        setshowModalreport(false);
    };

    const filter_code = () => {
        if (value_type_filter === "Customer") {
            if (textFilter !== "") {
                const filtered = all_machine.filter((customer) => {
                    return customer.code.toLowerCase().includes(textFilter.toLowerCase());
                });
                setall_machine(filtered);
                setIfapplyfilter(true);
            } else {
                setFilterAction(true);
            }
        } else if (value_type_filter === "Brand") {
            if (textFilter !== "") {
                const filtered = all_machine.filter((customer) => {
                    return customer.brand
                        .toLowerCase()
                        .includes(textFilter.toLowerCase());
                });
                setall_machine(filtered);
                setIfapplyfilter(true);
            } else {
                setFilterAction(true);
            }
        } else if (value_type_filter === "Model") {
            if (textFilter !== "") {
                const filtered = all_machine.filter((customer) => {
                    return customer.model
                        .toLowerCase()
                        .includes(textFilter.toLowerCase());
                });
                setall_machine(filtered);
                setIfapplyfilter(true);
            } else {
                setFilterAction(true);
            }
        } else if (value_type_filter === "Type") {
            if (textFilter !== "") {
                const filtered = all_machine.filter((customer) => {
                    return customer.type.toLowerCase().includes(textFilter.toLowerCase());
                });
                setall_machine(filtered);
                setIfapplyfilter(true);
            } else {
                setFilterAction(true);
            }
        } else if (value_type_filter === "Serial number") {
            if (textFilter !== "") {
                const filtered = all_machine.filter((customer) => {
                    return customer.serialNumber
                        .toLowerCase()
                        .includes(textFilter.toLowerCase());
                });
                setall_machine(filtered);
                setIfapplyfilter(true);
            } else {
                setFilterAction(true);
            }
        } else if (value_type_filter === "Internal number") {
            if (textFilter !== "") {
                const filtered = all_machine.filter((customer) => {
                    return customer.internalNumber
                        .toLowerCase()
                        .includes(textFilter.toLowerCase());
                });
                setall_machine(filtered);
                setIfapplyfilter(true);
            } else {
                setFilterAction(true);
            }
        } else if (value_type_filter === "Engine") {
            if (textFilter !== "") {
                const filtered = all_machine.filter((customer) => {
                    return customer.engine
                        .toLowerCase()
                        .includes(textFilter.toLowerCase());
                });
                setall_machine(filtered);
                setIfapplyfilter(true);
            } else {
                setFilterAction(true);
            }
        }
    };
    //Actions part

    const handleViewModalreport = (d) => {
        Axios.post(url + "?p=get_report", {
            id: d.id,
        }).then((response) => {
            setall_report(response.data);
        });

        setshowModalreport(true);
        //setMaintenanceData(d);
    };
    function load_data() {
        Axios.post(url + "?p=get_machine").then((response) => {
            setall_machine(response.data);
            setall_machine1(response.data);
        });
    }
    useEffect(() => {
        load_data();
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const [linePerPage, setlinePerPage] = useState(13);

    const indexOfLastPost = currentPage * linePerPage;
    const indexOfFirstPost = indexOfLastPost - linePerPage;

    const filteredallMachine = all_machine.filter(
        (option) =>
            option.brand?.toLowerCase().includes(filtervalue.toLowerCase()) ||
            option.code?.toLowerCase().includes(filtervalue.toLowerCase()) ||
            option.model?.toLowerCase().includes(filtervalue.toLowerCase()) ||
            option.type?.toLowerCase().includes(filtervalue.toLowerCase())
    );

    const currentPosts = filteredallMachine.slice(
        indexOfFirstPost,
        indexOfLastPost
    );

    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = 0;
        totalPages = Math.ceil(totalPosts / postsPerPage)

        if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 2; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage == totalPages) {
            if (currentPage - 5 >= 0) startlink = currentPage - 4;
            else if (currentPage - 4 >= 0) startlink = currentPage - 3;
            else if (currentPage - 3 >= 0) startlink = currentPage - 2;
            else if (currentPage - 2 >= 0) startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages) pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        //alert(currentPage)
        useEffect(() => {
            // calculate()
        }, [totalPosts])

        return (
            <>
                <nav className="d-flex">
                    <ul className="pagination">
                        <li className="page-item">
                            {
                                <span onClick={ () => paginate(1) } className="">
                                    { "«" }
                                </span>
                            }
                        </li>

                        <li className="page-item">
                            { currentPage == 1 ? (
                                <span className="" disabled>
                                    { "<" }
                                </span>
                            ) : (
                                <a
                                    onClick={ () => paginate(currentPage - 1) }
                                    href="#"
                                    className=""
                                >
                                    { "<" }
                                </a>
                            ) }
                        </li>

                        { pageNumbers.map((number) => (
                            <li key={ number } className="page-item">
                                { currentPage == number ? (
                                    <a
                                        onClick={ () => paginate(number) }
                                        href="#"
                                        className="bg-red"
                                    >
                                        { number }
                                    </a>
                                ) : (
                                    <a onClick={ () => paginate(number) } href="#" className="">
                                        { number }
                                    </a>
                                ) }
                            </li>
                        )) }

                        <li className="page-item">
                            { currentPage == totalPages ? (
                                <a href="#" className="" disabled>
                                    { ">" }
                                </a>
                            ) : (
                                <a
                                    onClick={ () => paginate(currentPage + 1) }
                                    href="#"
                                    className=""
                                >
                                    { ">" }
                                </a>
                            ) }
                        </li>

                        <li className="page-item">
                            {
                                <a onClick={ () => paginate(totalPages) } href="#" className="">
                                    { "»" }
                                </a>
                            }
                        </li>
                    </ul>
                </nav>
            </>
        );
    };

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    //Visual part
    return (
        <Main>
            <Header />
            <Header_mobile />
            <Modal show={ showModalreport } onHide={ handleClose } centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{ t("Machine.12") }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 ">
                            <div className="text-center text-justify h-40px font-weight-bold bg-secondary">
                                <h5 className="text-center text-justify h-40px font-weight-bold mt-25px bg-secondary pt-3">
                                    { t("Machine.13") }
                                </h5>
                            </div>
                            <table id="customers" className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="w-25px">
                                            #
                                        </th>

                                        <th scope="col" className="text-left">
                                            { t("Machine.14") }
                                            <a
                                                onClick={ () =>
                                                    handleModalShow("Code", "Filter data based on CODE")
                                                }
                                                data-toggle="modal"
                                                data-target=""
                                            ></a>
                                        </th>
                                        <th scope="col" className="text-left">
                                            { t("Machine.15") }
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#ticketsubjectModal"
                                                onClick={ () =>
                                                    handleModalShow("Name", "Filter data based on NAME")
                                                }
                                            ></a>
                                        </th>
                                        <th scope="col" className="text-left">
                                            { t("Machine.16") }
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#ticketdescModal"
                                                onClick={ () =>
                                                    handleModalShow(
                                                        "Username",
                                                        "Filter data based on USERNAME"
                                                    )
                                                }
                                            ></a>
                                        </th>
                                        <th scope="col" className="text-left">
                                            { t("Machine.17") }
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#ticketdescModal"
                                                onClick={ () =>
                                                    handleModalShow(
                                                        "Username",
                                                        "Filter data based on USERNAME"
                                                    )
                                                }
                                            ></a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Action
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                                onClick={ () =>
                                                    handleModalShow(
                                                        "Contact",
                                                        "Filter data based on CONTACT"
                                                    )
                                                }
                                            ></a>
                                        </th>
                                        <th scope="col" className="text-left"></th>
                                    </tr>
                                </thead>
                                <tbody id="resultdata">
                                    { all_report ? (
                                        all_report.map((d, index) => (
                                            <tr key={ index } id='td"+ d[i].m_id +"' className="data">
                                                <td id="tb-assigned-to" className="text-left">
                                                    { d.id }
                                                </td>
                                                <td id="tb-created-date" className="text-left">
                                                    { d.name }
                                                </td>
                                                <td id="tb-created-date" className="text-left">
                                                    { d.customerid }
                                                </td>
                                                <td id="tb-created-date" className="text-left">
                                                    { d.createdby }
                                                </td>
                                                <td id="tb-created-date" className="text-left">
                                                    { d.createddate }
                                                </td>
                                                <td id="tb-created-date" className="text-left">
                                                    <button
                                                        className="text-info border-0 bg-white "
                                                        onClick={ () => {
                                                            navigate("/admin/report", {
                                                                state: {
                                                                    reportid: d.id,
                                                                    machineid: d.machineid,
                                                                },
                                                            });
                                                        } }
                                                    >
                                                        { t("Machine.19") }
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={ 11 } className="text-center pt-20 fs-16">
                                                No data found
                                            </td>
                                        </tr>
                                    ) }
                                </tbody>
                            </table>
                            {/* <div className="card-footer pt-2 pb-2 col-8">
                <div className="d-flex justify-content-center">
                  <PaginationModel
                    postsPerPage={lineModelPerPage}
                    totalPosts={all_report.length}
                    paginate={paginateModel}
                  />
                </div>
              </div> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ handleClose }>
                        { t("Machine.18") }
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={ showfiltermodal } onHide={ handleModalClose } centered>
                <Modal.Header closeButton>
                    <Modal.Title>{ filter_title }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group col-xl-12 mt-55">
                            <div className="" align="left">
                                <label className="font-weight-bold">
                                    { value_type_filter }
                                    <span className="text-danger pl-3 ">*</span>
                                </label>
                            </div>
                            <input
                                id="cstm-code"
                                onChange={ (e) => {
                                    setTtextFilter(e.target.value);
                                } }
                                name="code"
                                value={ textFilter }
                                className="form-control rounded-0"
                            />
                            <div id="cstm-code-error" className="text-danger pt-2">
                                { messageModal }
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ handleModalClose }>
                        { t("Machine_descriptors.8") }
                    </Button>
                    <Button
                        variant="primary"
                        onClick={ () => {
                            filter_code();
                            handleModalClose();
                        } }
                        className="btn bg-red "
                    >
                        Filter
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="col-xl-12 center-block" align="center">
                <div
                    className="col-xl-8 bg-white mt-50 center-block min-h-650px max-h-650px"
                    align="center"
                >
                    <div className="pt-10 w-100 h-80px" style={ { marginLeft: "0.8rem" } }>
                        <h3 className="float-left"> { t("Machine.9") }</h3>
                    </div>

                    <div className="h-550px overflow-auto">
                        <div align={ "left" } className="col-xl-12">
                            { showfilter ? (
                                <input
                                    type="texte"
                                    className="ml-3"
                                    onChange={ (e) => {
                                        setfilterValue(e.target.value);
                                    } }
                                    value={ filtervalue }
                                />
                            ) : null }

                            { filtervalue === "" ? (
                                <FcFilledFilter
                                    size={ 20 }
                                    className="ml-5"
                                    onClick={ () => {
                                        setshowfilter(!showfilter);
                                    } }
                                />
                            ) : (
                                <FcClearFilters
                                    size={ 20 }
                                    className="ml-3"
                                    onClick={ () => {
                                        setfilterValue("");
                                    } }
                                />
                            ) }
                        </div>

                        <table id="customers" className="table ">
                            <thead className="position-fixe">
                                <tr>
                                    <th scope="col" className="w-25px">
                                        ID
                                    </th>

                                    <th scope="col" className="text-left">
                                        { t("Machine.10") }
                                    </th>

                                    <th scope="col" className="text-left">
                                        { t("Machine.2") }
                                    </th>
                                    <th scope="col" className="text-left">
                                        { t("Machine.3") }
                                    </th>
                                    <th scope="col" className="text-left">
                                        { t("Machine.7") }
                                    </th>
                                    <th scope="col" className="text-left">
                                        { t("Machine.4") }
                                    </th>
                                    <th scope="col" className="text-left">
                                        { t("Machine.5") }
                                    </th>
                                    <th scope="col" className="text-left">
                                        { t("Machine.6") }
                                    </th>
                                    <th scope="col" className="text-left">
                                        { t("Machine.11") }
                                    </th>
                                </tr>
                            </thead>

                            <tbody id="resultdata">
                                { currentPosts ? (
                                    currentPosts.map((d, index) => (
                                        <tr
                                            key={ index }
                                            id='td"+ d[i].m_id +"'
                                            className="data display-block"
                                        >
                                            <td id="tb-assigned-to" className="text-left">
                                                { d.id }
                                            </td>
                                            <td
                                                id="tb-assigned-to"
                                                className="text-left text-info hover-underline"
                                                onClick={ () => handleViewModalreport(d) }
                                            >
                                                { d.code }
                                            </td>
                                            <td className="text-left">{ d.brand }</td>
                                            <td className="text-left">{ d.model }</td>
                                            <td className="text-left">{ d.type }</td>
                                            <td className="text-left">{ d.serialNumber }</td>
                                            <td className="text-left">{ d.internalNumber }</td>
                                            <td className="text-left">{ d.engine }</td>
                                            <td>
                                                <span
                                                    className={
                                                        d.active === 1
                                                            ? " bg-success p-2 h-10px text-white rounded"
                                                            : " bg-danger p-2 text-white rounded "
                                                    }
                                                >
                                                    { d.active === 1 ? "Active" : "Inactive" }
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={ 11 } className="text-center pt-20 fs-16">
                                            No data found
                                        </td>
                                    </tr>
                                ) }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer pt-2 pb-2 col-8">
                    <div className="d-flex justify-content-center">
                        <Pagination
                            postsPerPage={ linePerPage }
                            totalPosts={ filteredallMachine.length }
                            paginate={ paginate }
                        />
                    </div>
                </div>
            </div>

            <Footer />
        </Main>
    );
}

export default Customer_list;
