import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import {
    Search,
} from "react-bootstrap-icons";
import { useOutsideFinder } from '../utils/useOutsideFinder';

const SearchDropdown = ({ onChange, data, selectedValue }) => {
    const divRef = useRef()
    const [searchValue, setSearchValue] = useState('');
    const [showData, setShowData] = useState(false);

    const handleClick = (item) => {
        onChange(item)
        setShowData(false)
    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setSearchValue(inputValue);
    };
    
    const filtered = data ? data?.filter(
        item => 
            item?.itemName?.toLowerCase().toString().includes(searchValue.toLowerCase()) || 
            item?.itemCode?.toString().toLowerCase().includes(searchValue.toLowerCase())) : [];

    useOutsideFinder(() => setShowData(false), divRef)
    useEffect(() => setSearchValue(''), [])

    return (
        <div className="dropdown w-100">
            <div ref={divRef}>
                <input value={searchValue} className="btn border dropdown-toggle w-100 text-left"  onClick={() => {setShowData(true); setSearchValue('')} }  onChange={handleInputChange}  />

                    { showData &&
                        <div className="absolute  justify-content-between align-items-center mt-2 p-2 w-full" style={{ position: 'absolute', zIndex: '15', width: '100%', backgroundColor: 'gray' }}>
                        <div className='flex d-flex justify-content-center align-items-center' style={{ width: '100%' }}>
                            <Search size={20} />
                            <input
                                type="text"
                                className="form-control w-100 border-0 w-100 ml-2"
                                placeholder="Search..."
                                onChange={(e) => {handleInputChange(e)}}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className=' hover:bg-white cursor-pointer mt-2' style={{maxHeight:'250px', overflow:'auto'}}>
                            {
                                filtered && filtered.length > 0 ?
                                filtered.map((item, index) => {
                                    return <div key={index} className=' bg-white p-2 text-left hover-underline border-1' onClick={() => {handleClick(item); setSearchValue(item.itemName) }} title={item?.itemName} style={{}} >{item.itemName}</div>;
                                }) : null
                            }
                        </div>
                    </div>}
            </div>
        </div>
    );
};

export default SearchDropdown;