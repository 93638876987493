import Header from "./header";
import Footer from "./footer";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import Header_mobile from "./header_mobile";
import { FcFilledFilter, FcClearFilters } from "react-icons/fc";
import Main from "../Main";

function Contact_list() {
  //Variable part
  const { t } = useTranslation();
  const [all_contact, setAll_contact] = useState([]);
  const url = process.env.REACT_APP_BASEURL;
  const [showfilter, setshowfilter] = useState(false);
  const [filtervalue, setfilterValue] = useState("");
  //Actions part

  useEffect(() => {
    Axios.post(url + "?p=get_contact").then((response) => {
      setAll_contact(response.data);
    });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [linePerPage, setlinePerPage] = useState(10);

  const indexOfLastPost = currentPage * linePerPage;
  const indexOfFirstPost = indexOfLastPost - linePerPage;

  const filteredallContact = all_contact.filter(
    (option) =>
      option.name.toLowerCase().includes(filtervalue.toLowerCase()) ||
      option.email.toLowerCase().includes(filtervalue.toLowerCase())
  );

  const currentPosts = filteredallContact.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    var pageNumbers = [];
    var pageslink = 6;
    var startlink = 1;

    var totalPages = Math.ceil(totalPosts / postsPerPage);

    if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
      startlink = currentPage - 2;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 2; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
      startlink = currentPage - 3;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage === totalPages) {
      if (currentPage - 5 >= 0) startlink = currentPage - 4;
      else if (currentPage - 4 >= 0) startlink = currentPage - 3;
      else if (currentPage - 3 >= 0) startlink = currentPage - 2;
      else if (currentPage - 2 >= 0) startlink = currentPage - 1;
      pageNumbers = [];
      for (let i = startlink; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      startlink = 1;
      pageNumbers = [];
      if (pageslink > totalPages) pageslink = totalPages;
      for (let i = startlink; i <= pageslink; i++) {
        pageNumbers.push(i);
      }
    }

    //alert(currentPage)

    return (
      <>
        <nav className="d-flex">
          <ul className="pagination">
            <li className="page-item">
              {
                <a onClick={() => paginate(1)} href="#" className="">
                  {"«"}
                </a>
              }
            </li>

            <li className="page-item">
              {currentPage == 1 ? (
                <a href="#" className="" disabled>
                  {"<"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage - 1)}
                  href="#"
                  className=""
                >
                  {"<"}
                </a>
              )}
            </li>

            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                {currentPage == number ? (
                  <a
                    onClick={() => paginate(number)}
                    href="#"
                    className="bg-red"
                  >
                    {number}
                  </a>
                ) : (
                  <a onClick={() => paginate(number)} href="#" className="">
                    {number}
                  </a>
                )}
              </li>
            ))}

            <li className="page-item">
              {currentPage == totalPages ? (
                <a href="#" className="" disabled>
                  {">"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage + 1)}
                  href="#"
                  className=""
                >
                  {">"}
                </a>
              )}
            </li>

            <li className="page-item">
              {
                <a onClick={() => paginate(totalPages)} href="#" className="">
                  {"»"}
                </a>
              }
            </li>
          </ul>
        </nav>
      </>
    );
  };

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //Visual part
  return (
    <Main>
      <Header_mobile />
      <Header />

      <div className="col-xl-12 center-block" align="center">
        <div
          className="col-xl-8 bg-white mt-50 center-block min-h-650px max-h-650px"
          align="center"
        >
          <div className="pt-10" align="left" style={{ marginLeft: "0.8rem" }}>
            <h3>{t("Add_contact.6")}</h3>
          </div>
          <div align={"left"} className="col-xl-12">
            {showfilter ? (
              <input
                type="texte"
                className="ml-3"
                onChange={(e) => {
                  setfilterValue(e.target.value);
                }}
                value={filtervalue}
              />
            ) : null}

            {filtervalue === "" ? (
              <FcFilledFilter
                size={20}
                className="ml-5"
                onClick={() => {
                  setshowfilter(!showfilter);
                }}
              />
            ) : (
              <FcClearFilters
                size={20}
                className="ml-3"
                onClick={() => {
                  setfilterValue("");
                }}
              />
            )}
          </div>
          <table id="contact" className="table">
            <thead>
              <tr>
                <th scope="col" className="w-25px">
                  #
                </th>

                <th scope="col" className="text-left">
                  {t("Add_customer.3")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#ticketdescModal"
                  ></a>
                </th>

                <th scope="col" className="text-left">
                  {t("Add_contact.2")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#ticketidModal"
                  ></a>
                </th>
                <th scope="col" className="text-left">
                  {t("Add_contact.3")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#ticketsubjectModal"
                  ></a>
                </th>
                <th scope="col" className="text-left">
                  {t("Add_contact.4")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#ticketdescModal"
                  ></a>
                </th>
                <th scope="col" className="text-left">
                  {t("Report.19")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#ticketdescModal"
                  ></a>
                </th>

                <th scope="col" className="text-left">
                  {t("Add_customer.7")}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#ticketdescModal"
                  ></a>
                </th>

                <th scope="col" className="text-left"></th>
              </tr>
            </thead>
            <tbody id="resultdata">
              {currentPosts ? (
                currentPosts.map((d, index) => (
                  <tr key={index} id='td"+ d[i].m_id +"' className="data">
                    <td id="tb-assigned-to" className="text-left">
                      {d.id}
                    </td>
                    <td id="tb-created-date" className="text-left">
                      {d.name}
                    </td>
                    <td id="tb-created-date" className="text-left">
                      {d.phone}
                    </td>
                    <td id="tb-created-date" className="text-left">
                      {d.whatsappPhone}
                    </td>
                    <td id="tb-created-date" className="text-left">
                      {d.email}
                    </td>
                    <td id="tb-created-date" className="text-left">
                      {d.positionName}
                    </td>
                    <td id="tb-created-date" className="text-left">
                      {d.active === 1 ? "Yes" : "No"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={11} className="text-center pt-20 fs-16">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="card-footer pt-2 pb-2 col-8">
          <div className="d-flex justify-content-center">
            <Pagination
              postsPerPage={linePerPage}
              totalPosts={filteredallContact.length}
              paginate={paginate}
            />
          </div>
        </div>
      </div>

      <Footer />
    </Main>
  );
}

export default Contact_list;
