import Header from "./header";
import Footer from "./footer";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useState, useEffect } from "react";
import Header_mobile from "./header_mobile";
import Main from "../Main";
import SearchDropdown from "./dropdownWithSearch";

function Add_machine() {
  //Variable part
  const { t } = useTranslation();

  const [mch_brand, setMch_brand] = useState(0);
  const [messageBrand, setMessageBrand] = useState("");
  const [mch_model, setMch_model] = useState(0);
  const [messageModel, setMessageModel] = useState("");
  const [mch_serial, setMch_serial] = useState("");
  const [messageSerial, setMessageSerial] = useState("");
  const [mch_internalNumber, setMch_internalNumber] = useState("");
  const [messageinternalNumber, setMessageinternalNumber] = useState("");
  const [mch_Engine, setMch_Engine] = useState("");
  const [messageEngine, setMessageEngine] = useState("");
  const [mch_type, setMch_type] = useState(0);
  const [messageType, setMessageType] = useState("");
  const [all_type, setAll_type] = useState("");
  const [all_model, setAll_model] = useState("");
  const [all_brand, setAll_brand] = useState("");
  const [all_customer, setAll_customer] = useState("");
  const [customer, setCustomer] = useState(0);
  const [messageCustomer, setMessageCustomer] = useState("");

  const [verif_all_data, setVerif_all_data] = useState(0);
  const url = process.env.REACT_APP_BASEURL;

  const notifySuccess = (msg) =>
    toast.success(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const notifyError = (msg) =>
    toast.error(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const getBrands = () => {
    Axios.post(url + "?p=get_type").then((response) => {
      const a = []
      response.data.map((type) =>
        a.push({ itemCode: type.id, itemName: type.type })
      )
      setAll_type(a);
    });
    Axios.post(url + "?p=get_model").then((response) => {
      const a = []
      response.data.map((item) =>
        a.push({ itemCode: item.id, itemName: item.model })
      )
      setAll_model(a);
    });
    Axios.post(url + "?p=get_brand").then((response) => {
      const a = []
      response.data.map((item) =>
        a.push({ itemCode: item.id, itemName: item.brand })
      )
      setAll_brand(a);
    });
    Axios.post(url + "?p=get_customer").then((response) => {
      const a = []
      response.data.map((item) =>
        a.push({ itemCode: item.id, itemName: item.code })
      )
      setAll_customer(a);
    });
  };

  useEffect(() => {
    getBrands();
  }, []);

  const add_machine = () => {
    if (customer === 0) {
      setMessageCustomer("Please choose the customer.");
      setVerif_all_data(1);
    } else {
      setMessageCustomer("");
    }
    if (
      customer !== 0
    ) {
      Axios.post(url + "?p=add_machine", {
        type_id: mch_type.itemCode,
        brand: mch_brand.itemCode,
        model: mch_model.itemCode,
        serial: mch_serial,
        internal: mch_internalNumber,
        engin: mch_Engine,
        customer_id: customer.itemCode,
      }).then((response) => {
        if (response.data.type_action) {
          notifySuccess("New machine has been added");
          setMch_serial("");
          setMch_internalNumber("");
          setMch_brand(0);
          setMch_model(0);
          setMch_Engine("");
          setMch_type(0);
          setCustomer(0);
        } else {
          if (response.data.errorno === "123456")
            notifyError("Serial or Internal number already exist");
          else notifyError("Somrthing wrong");
        }
      });
    }
  };

  return (
    <Main>
      <Header />
      <Header_mobile />

      <div className="mt-50 col-xl-12 pb-20 center-block" align="center">
        <div className="col-xl-3 bg-white rounded ">
          <div className="pt-50">
            <h3>{t("Machine.1")}</h3>
          </div>
          <form>
            <div className="form-group mb-4 mt-3 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Machine.2")}
                  <span className="text-danger pl-3"></span>
                </label>
              </div>
              <SearchDropdown onChange={(e) => setMch_brand(e)} data={all_brand} selectedValue={mch_brand} />
              <div id="cstm-contact-error" className="text-danger pt-3">
                {messageBrand}
              </div>
            </div>

            <div className="form-group mb-4 mt-3 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Machine.3")}
                  <span className="text-danger pl-3"></span>
                </label>
              </div>
              <SearchDropdown onChange={(e) => setMch_model(e)} data={all_model} selectedValue={mch_model} />
              <div id="cstm-contact-error" className="text-danger pt-3">
                {messageModel}
              </div>
            </div>

            <div className="form-group mb-4 mt-3 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Header.2")}
                  <span className="text-danger pl-3">*</span>
                </label>
              </div>
              <SearchDropdown onChange={(e) => setCustomer(e)} data={all_customer} selectedValue={customer} />
              <div id="cstm-costomer-error" className="text-danger pt-3">
                {messageCustomer}
              </div>
            </div>

            <div className="form-group mb-4 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Machine.4")}
                  <span className="text-danger pl-3"></span>
                </label>
              </div>
              <input
                id="cstm-username"
                onChange={(e) => setMch_serial(e.target.value)}
                name="username"
                value={mch_serial}
                className="form-control rounded-0"
              />
              <div id="cstm-username-error" className="text-danger pt-2">
                {messageSerial}
              </div>
            </div>

            <div className="form-group mb-4 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Machine.5")}
                  <span className="text-danger pl-3"></span>
                </label>
              </div>
              <input
                type="text"
                id="cstm-password"
                onChange={(e) => setMch_internalNumber(e.target.value)}
                name="password"
                value={mch_internalNumber}
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {messageinternalNumber}
              </div>
            </div>

            <div className="form-group mb-4 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">{t("Machine.6")}</label>
              </div>
              <input
                id="cstm-username"
                onChange={(e) => setMch_Engine(e.target.value)}
                name="engine"
                value={mch_Engine}
                className="form-control rounded-0"
              />
              <div id="cstm-username-error" className="text-danger pt-2">
                {messageEngine}
              </div>
            </div>

            <div className="form-group mb-4 mt-3 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Machine.7")}
                  <span className="text-danger pl-3"></span>
                </label>
              </div>
              <SearchDropdown onChange={(e) => setMch_type(e)} data={all_type} selectedValue={mch_type} />
              <div id="cstm-contact-error" className="text-danger pt-3">
                {messageType}
              </div>
            </div>
          </form>
          <button
            onClick={add_machine}
            type="submit"
            className="btn mb-4 bg-red col-xl-12 "
          >
            {" "}
            {t("Machine.8")}
          </button>
        </div>
      </div>

      <Footer />
    </Main>
  );
}

export default Add_machine;
