import Header from "./header";
import Footer from "./footer";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Header_mobile from "./header_mobile";
import { FcFilledFilter, FcClearFilters } from "react-icons/fc";
import Main from "../Main";
import { AiFillEdit } from "react-icons/ai";

function Customer_list() {
  //Variable part
  const { t } = useTranslation();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_BASEURL;

  const [all_customer, setall_customer] = useState([]);
  const [all_customer1, setall_customer1] = useState([]);
  const [all_report, setall_report] = useState([]);
  const [all_customer_machine, setall_customer_machine] = useState([]);
  const [all_customer_info, setall_customer_info] = useState([
    {
      id: "",
      code: "",
      username: "",
      password: "",
      name: null,
      siteId: "",
      active: "",
      whatsappPhone: null,
      email: null,
    },
  ]);

  const [showfiltermodal, setShowfiltermodal] = useState(false);
  const [messageModal, setMessageModal] = useState("");
  const [textFilter, setTtextFilter] = useState("");
  const [value_type_filter, SetValue_type_filter] = useState();
  const [filter_title, setFiltertitle] = useState("");
  const [filteraction, setFilterAction] = useState(true);
  const [ifapplyfilter, setIfapplyfilter] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalreport, setshowModalreport] = useState(false);
  const [filtervalue, setfilterValue] = useState("");
  const [showfilter, setshowfilter] = useState(false);
  const [viewsection, setviewsection] = useState("1");
  const [allSite, setallSite] = useState([]);
  const [contactInfo, setcontactInfo] = useState([]);

  const handleModalShow = (type, title) => {
    if (ifapplyfilter) setall_customer(all_customer1);
    setShowfiltermodal(true);
    SetValue_type_filter(type);
    setFiltertitle(title);
  };

  const handleupdate = (d) => {
    navigate(`/admin/update_customer/${d.id}`);
  };

  const handleViewModal = (d) => {
    setcontactInfo(null)
    Axios.post(url + "?p=get_customer_machine", {
      id: d.id,
    }).then((response) => {
      setall_customer_machine(response.data);
    });

    Axios.post(url + "?p=get_customer_info", {
      id: d.id,
    }).then((response) => {
      setall_customer_info(response.data);
    });

    Axios.post(url + "?p=get_customerSite", {
      id_customer: d.id,
    }).then((response) => {
      setallSite(response.data);
      console.log(response.data);
    });

    setShowModal(true);
  };

  const handleClose = () => {
    setshowModalreport(false);
    setShowModal(false);
  };

  const get_site_contact = (d) => {
    Axios.post(url + "?p=get_site_contact", {
      id: d.id,
    }).then((response) => {
      setcontactInfo(response.data.data[0]);
    });
  }

  const filter_code = () => {
    if (value_type_filter === "Code") {
      if (textFilter !== "") {
        const filtered = all_customer.filter((customer) => {
          return customer.code === textFilter;
        });
        setall_customer(filtered);
        setIfapplyfilter(true);
      } else {
        setFilterAction(true);
      }
    } else if (value_type_filter === "Name") {
      if (textFilter !== "") {
        const filtered = all_customer.filter((customer) => {
          return customer.name.toLowerCase().includes(textFilter.toLowerCase());
        });
        setall_customer(filtered);
        setIfapplyfilter(true);
      } else {
        setFilterAction(true);
      }
    } else if (value_type_filter === "Username") {
      if (textFilter !== "") {
        const filtered = all_customer.filter((customer) => {
          return customer.username
            .toLowerCase()
            .includes(textFilter.toLowerCase());
        });
        setall_customer(filtered);
        setIfapplyfilter(true);
      } else {
        setFilterAction(true);
      }
    } else if (value_type_filter === "Contact") {
      if (textFilter !== "") {
        const filtered = all_customer.filter((customer) => {
          return customer.contact_name
            .toLowerCase()
            .includes(textFilter.toLowerCase());
        });
        setall_customer(filtered);
        setIfapplyfilter(true);
      } else {
        setFilterAction(true);
      }
    }
  };

  //Actions part
  function load_data() {
    Axios.post(url + "?p=get_customer").then((response) => {
      setall_customer(response.data);
      setall_customer1(response.data);
    });
  }

  useEffect(() => {
    if (sessionStorage.getItem("username") === "") navigate("/home");
    load_data();
  }, [filteraction]);
  const handleModalClose = () => {
    setShowfiltermodal(false);
    setMessageModal("");
    setTtextFilter("");
  };

  ////////////////////////////////////////////////////PAGINATION MODEL////////////////////////////////////////////////

  const [currentModelPage, setcurrentModelPage] = useState(1);
  const [lineModelPerPage, setlineModelPerPage] = useState(8);

  const indexOfLastModelPost = currentModelPage * lineModelPerPage;
  const indexOfFirstModelPost = indexOfLastModelPost - lineModelPerPage;
  const currentModelPosts = all_customer_machine.slice(
    indexOfFirstModelPost,
    indexOfLastModelPost
  );

  const PaginationModel = ({ postsPerPage, totalPosts, paginate }) => {
    var pageNumbers = [];
    var pageslink = 6;
    var startlink = 1;

    var totalPages = Math.ceil(totalPosts / postsPerPage);

    if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
      startlink = currentPage - 2;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 2; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
      startlink = currentPage - 3;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage === totalPages) {
      if (currentPage - 5 >= 0) startlink = currentPage - 4;
      else if (currentPage - 4 >= 0) startlink = currentPage - 3;
      else if (currentPage - 3 >= 0) startlink = currentPage - 2;
      else if (currentPage - 2 >= 0) startlink = currentPage - 1;
      pageNumbers = [];
      for (let i = startlink; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      startlink = 1;
      pageNumbers = [];
      if (pageslink > totalPages) pageslink = totalPages;
      for (let i = startlink; i <= pageslink; i++) {
        pageNumbers.push(i);
      }
    }

    return (
      <>
        <nav>
          <ul className="pagination">
            <li className="page-item">
              {
                <span onClick={() => paginateModel(1)} href="#" className="">
                  {"«"}
                </span>
              }
            </li>

            <li className="page-item">
              {currentModelPage === 1 ? (
                <span href="#" className="" disabled>
                  {"<"}
                </span>
              ) : (
                <span
                  onClick={() => paginateModel(currentModelPage - 1)}
                  href="#"
                  className=""
                >
                  {"<"}
                </span>
              )}
            </li>

            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                {currentModelPage === number ? (
                  <span
                    onClick={() => paginateModel(number)}
                    href="#"
                    className="bg-red"
                  >
                    {number}
                  </span>
                ) : (
                  <span
                    onClick={() => paginateModel(number)}
                    href="#"
                    className=""
                  >
                    {number}
                  </span>
                )}
              </li>
            ))}

            <li className="page-item">
              {currentModelPage === totalPages ? (
                <span href="#" className="" disabled>
                  {">"}
                </span>
              ) : (
                <span
                  onClick={() => paginateModel(currentModelPage + 1)}
                  href="#"
                  className=""
                >
                  {">"}
                </span>
              )}
            </li>

            <li className="page-item">
              {
                <span
                  onClick={() => paginateModel(totalPages)}
                  href="#"
                  className=""
                >
                  {"»"}
                </span>
              }
            </li>
          </ul>
        </nav>
      </>
    );
  };

  const paginateModel = (pageNumber) => setcurrentModelPage(pageNumber);

  const [currentPage, setCurrentPage] = useState(1);
  const [linePerPage, setlinePerPage] = useState(13);

  const indexOfLastPost = currentPage * linePerPage;
  const indexOfFirstPost = indexOfLastPost - linePerPage;
  const filteredallCustomer = all_customer.filter(
    (option) =>
      option.code.toLowerCase().includes(filtervalue.toLowerCase()) ||
      option.username.toLowerCase().includes(filtervalue.toLowerCase())
  );
  const currentPosts = filteredallCustomer.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    var pageNumbers = [];
    var pageslink = 6;
    var startlink = 1;

    var totalPages = Math.ceil(totalPosts / postsPerPage);

    if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
      startlink = currentPage - 2;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 2; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
      startlink = currentPage - 3;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage === totalPages) {
      if (currentPage - 5 >= 0) startlink = currentPage - 4;
      else if (currentPage - 4 >= 0) startlink = currentPage - 3;
      else if (currentPage - 3 >= 0) startlink = currentPage - 2;
      else if (currentPage - 2 >= 0) startlink = currentPage - 1;
      pageNumbers = [];
      for (let i = startlink; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      startlink = 1;
      pageNumbers = [];
      if (pageslink > totalPages) pageslink = totalPages;
      for (let i = startlink; i <= pageslink; i++) {
        pageNumbers.push(i);
      }
    }

    //alert(currentPage)

    return (
      <>
        <nav className="d-flex">
          <ul className="pagination">
            <li className="page-item">
              {
                <a onClick={() => paginate(1)} href="#" className="">
                  {"«"}
                </a>
              }
            </li>

            <li className="page-item">
              {currentPage == 1 ? (
                <a href="#" className="" disabled>
                  {"<"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage - 1)}
                  href="#"
                  className=""
                >
                  {"<"}
                </a>
              )}
            </li>

            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                {currentPage == number ? (
                  <a
                    onClick={() => paginate(number)}
                    href="#"
                    className="bg-red"
                  >
                    {number}
                  </a>
                ) : (
                  <a onClick={() => paginate(number)} href="#" className="">
                    {number}
                  </a>
                )}
              </li>
            ))}

            <li className="page-item">
              {currentPage == totalPages ? (
                <a href="#" className="" disabled>
                  {">"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage + 1)}
                  href="#"
                  className=""
                >
                  {">"}
                </a>
              )}
            </li>

            <li className="page-item">
              {
                <a onClick={() => paginate(totalPages)} href="#" className="">
                  {"»"}
                </a>
              }
            </li>
          </ul>
        </nav>
      </>
    );
  };

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //Actions part

  //Visual part
  return (
    <Main>
      <Header_mobile />
      <Header />
      <Modal show={showModalreport} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Customer Report(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 ">
              <div className="text-center text-justify h-40px font-weight-bold bg-secondary">
                <h5 className="text-center text-justify h-40px font-weight-bold mt-25px bg-secondary pt-3">
                  Report(s)
                </h5>
              </div>
              <table id="customers" className="table">
                <thead>
                  <tr>
                    <th scope="col" className="w-25px">
                      #
                    </th>

                    <th scope="col" className="text-left">
                      Name
                      <span
                        onClick={() =>
                          handleModalShow("Code", "Filter data based on CODE")
                        }
                        data-toggle="modal"
                        data-target=""
                      ></span>
                    </th>
                    <th scope="col" className="text-left">
                      Customer ID
                      <span
                        href="#"
                        data-toggle="modal"
                        data-target="#ticketsubjectModal"
                        onClick={() =>
                          handleModalShow("Name", "Filter data based on NAME")
                        }
                      ></span>
                    </th>
                    <th scope="col" className="text-left">
                      Created By
                      <span
                        href="#"
                        data-toggle="modal"
                        data-target="#ticketdescModal"
                        onClick={() =>
                          handleModalShow(
                            "Username",
                            "Filter data based on USERNAME"
                          )
                        }
                      ></span>
                    </th>
                    <th scope="col" className="text-left">
                      Created Date
                      <span
                        href="#"
                        data-toggle="modal"
                        data-target="#ticketdescModal"
                        onClick={() =>
                          handleModalShow(
                            "Username",
                            "Filter data based on USERNAME"
                          )
                        }
                      ></span>
                    </th>

                    <th scope="col" className="text-left">
                      Action
                      <span
                        href="#"
                        data-toggle="modal"
                        data-target="#priorityModal"
                        onClick={() =>
                          handleModalShow(
                            "Contact",
                            "Filter data based on CONTACT"
                          )
                        }
                      ></span>
                    </th>
                    <th scope="col" className="text-left"></th>
                  </tr>
                </thead>
                <tbody id="resultdata">
                  {all_report ? (
                    all_report.map((d, index) => (
                      <tr key={index} id='td"+ d[i].m_id +"' className="data">
                        <td id="tb-assigned-to" className="text-left">
                          {d.id}
                        </td>
                        <td id="tb-created-date" className="text-left">
                          {d.name}
                        </td>
                        <td id="tb-created-date" className="text-left">
                          {d.customerid}
                        </td>
                        <td id="tb-created-date" className="text-left">
                          {d.createdby}
                        </td>
                        <td id="tb-created-date" className="text-left">
                          {d.createddate}
                        </td>
                        <td id="tb-created-date" className="text-left">
                          <button
                            className="text-info border-0 bg-white "
                            onClick={() => {
                              navigate("/report", { state: { data: d.id } });
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={11} className="text-center pt-20 fs-16">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="card-footer pt-2 pb-2 col-8">
                <div className="d-flex justify-content-center">
                  <PaginationModel
                    postsPerPage={lineModelPerPage}
                    totalPosts={all_report.length}
                    paginate={paginateModel}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Customer Informations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 p-0">
              <Button
                variant="secondary"
                className={
                  viewsection === "1"
                    ? "rounded-0 ml-00 bg-info text-white"
                    : "rounded-0 ml-00"
                }
                onClick={() => {
                  setviewsection("1");
                }}
              >
                Information
              </Button>
              <Button
                variant="secondary"
                className={
                  viewsection === "2"
                    ? "rounded-0 ml-00 bg-info text-white"
                    : "rounded-0 ml-00"
                }
                onClick={() => {
                  setviewsection("2");
                }}
              >
                Machine
              </Button>
            </div>

            {viewsection === "1" ? (
              <div className="col-12 bg-light p-0 h-350px">
                <h5 className="text-center text-justify h-40px font-weight-bold mt-25px bg-secondary pt-3 col-12 bg-info text-white">
                  Customer informations
                </h5>
                <div className="d-flex">
                  <div className="col-4  p-0 m-0 border border-primary">
                    <h6 className="col-12 bg-primary p-3 text-white border-2">
                      Customer
                    </h6>
                    {all_customer_info ? (
                      <div className="mt-30px">
                        <p className="p-1">
                          Code : {all_customer_info[0]?.code}
                        </p>
                        <p className="p-1">
                          Name : {all_customer_info[0]?.username}
                        </p>
                        <p className="p-1">
                          Active :{" "}
                          {all_customer_info[0]?.active === 1 ? "Yes" : "No"}
                        </p>
                        <p className="p-1">
                          Site : {all_customer_info[0]?.siteName}
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-4 p-0 border border-primary">
                    <h6 className="col-12 bg-primary p-3 text-white border-2">
                      List site
                    </h6>
                    {allSite
                      ? allSite.map((item, index) => {
                        return (
                          <div
                            className="p-2 bg-success m-1 rounded text-white cursor-pointer"
                            key={index}
                            onClick={() => { get_site_contact(item) }}
                          >
                            {" "}
                            {item.siteName}
                          </div>
                        );
                      })
                      : null}
                  </div>
                  <div className="col-4 p-0 border border-primary">
                    <h6 className="col-12 bg-primary p-3 text-white border-2">
                      {contactInfo ? contactInfo.siteName + " contact" : "Choose a site to view the contact"}
                    </h6>
                    {contactInfo ? (
                      <div className="mt-30px">
                        <p className="p-1">
                          Name : {contactInfo.name}
                        </p>
                        <p className="p-1">
                          Phone : {contactInfo.phone}
                        </p>
                        <p className="p-1">
                          Whatsapp phone : {contactInfo.whatsappPhone}
                        </p>

                        <p className="p-1">
                          Email : {contactInfo.email}
                        </p>
                      </div>
                    ) : <div className="text-danger p-2 "> No contact informations </div>}
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-12 bg-light p-0 h-350px ">
                <h5 className="text-center text-justify h-40px font-weight-bold mt-25px bg-secondary pt-3 col-12 bg-info text-white ">
                  Customer Machines
                </h5>
                <div className="d-flex overflow-auto h-300px">
                  <div className="col-12 ">
                    <table id="customers" className="table">
                      <thead>
                        <tr>
                          <th scope="col" className="w-25px">
                            #
                          </th>

                          <th scope="col" className="text-left">
                            Internal number
                            <span
                              onClick={() =>
                                handleModalShow(
                                  "Code",
                                  "Filter data based on CODE"
                                )
                              }
                              data-toggle="modal"
                              data-target=""
                            ></span>
                          </th>
                          <th scope="col" className="text-left">
                            Serial Number
                            <span
                              href="#"
                              data-toggle="modal"
                              data-target="#ticketsubjectModal"
                              onClick={() =>
                                handleModalShow(
                                  "Name",
                                  "Filter data based on NAME"
                                )
                              }
                            ></span>
                          </th>
                          <th scope="col" className="text-left">
                            Type
                            <span
                              href="#"
                              data-toggle="modal"
                              data-target="#ticketdescModal"
                              onClick={() =>
                                handleModalShow(
                                  "Username",
                                  "Filter data based on USERNAME"
                                )
                              }
                            ></span>
                          </th>

                          <th scope="col" className="text-left">
                            Active
                            <span
                              href="#"
                              data-toggle="modal"
                              data-target="#priorityModal"
                              onClick={() =>
                                handleModalShow(
                                  "Contact",
                                  "Filter data based on CONTACT"
                                )
                              }
                            ></span>
                          </th>
                          <th scope="col" className="text-left"></th>
                        </tr>
                      </thead>
                      <tbody id="resultdata">
                        {currentModelPosts ? (
                          currentModelPosts.map((d, index) => (
                            <tr
                              key={index}
                              id='td"+ d[i].m_id +"'
                              className="data"
                            >
                              <td id="tb-assigned-to" className="text-left">
                                {d.id}
                              </td>
                              <td id="tb-created-date" className="text-left">
                                {d.internalNumber}
                              </td>
                              <td id="tb-created-date" className="text-left">
                                {d.serialNumber}
                              </td>
                              <td id="tb-created-date" className="text-left">
                                {d.type}
                              </td>
                              <td id="tb-created-date" className="text-left">
                                {d.active === 1 ? "Yes" : "No"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={11}
                              className="text-center pt-20 fs-16"
                            >
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="card-footer pt-2 pb-2 col-12">
                      <div className="d-flex justify-content-center">
                        <PaginationModel
                          postsPerPage={lineModelPerPage}
                          totalPosts={all_customer_machine.length}
                          paginate={paginateModel}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showfiltermodal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{filter_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group col-xl-12 mt-55">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {value_type_filter}
                  <span className="text-danger pl-3 ">*</span>
                </label>
              </div>
              <input
                id="cstm-code"
                onChange={(e) => {
                  setTtextFilter(e.target.value);
                }}
                name="code"
                value={textFilter}
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {messageModal}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            {t("Machine_descriptors.8")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              filter_code();
              handleModalClose();
            }}
            className="btn bg-red "
          >
            Filter
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="col-xl-12 center-block" align="center">
        <div
          className="col-xl-8 bg-white mt-50 center-block min-h-650px max-h-650px overflow-auto"
          align="center"
        >
          <div className="pt-10 w-100 h-80px" style={{ marginLeft: "0.8rem" }}>
            <div>
              <h3 className="float-left"> {t("Add_customer.9")}</h3>
            </div>
          </div>
          <div align={"left"} className="col-xl-12">
            {showfilter ? (
              <input
                type="texte"
                className="ml-3"
                onChange={(e) => {
                  setfilterValue(e.target.value);
                }}
                value={filtervalue}
              />
            ) : null}

            {filtervalue === "" ? (
              <FcFilledFilter
                size={20}
                className="ml-5"
                onClick={() => {
                  setshowfilter(!showfilter);
                }}
              />
            ) : (
              <FcClearFilters
                size={20}
                className="ml-3"
                onClick={() => {
                  setfilterValue("");
                }}
              />
            )}
          </div>
          <table id="customers" className="table">
            <thead>
              <tr>
                <th scope="col" className="w-25px"></th>
                <th scope="col" className="w-25px">
                  #
                </th>

                <th scope="col" className="text-left">
                  {t("Add_customer.2")}
                  <span
                    onClick={() =>
                      handleModalShow("Code", "Filter data based on CODE")
                    }
                    data-toggle="modal"
                    data-target=""
                  ></span>
                </th>
                <th scope="col" className="text-left">
                  {t("Add_customer.3")}
                  <span
                    href="#"
                    data-toggle="modal"
                    data-target="#ticketsubjectModal"
                    onClick={() =>
                      handleModalShow("Name", "Filter data based on NAME")
                    }
                  ></span>
                </th>
                <th scope="col" className="text-left">
                  {t("Add_customer.4")}
                  <span
                    href="#"
                    data-toggle="modal"
                    data-target="#ticketdescModal"
                    onClick={() =>
                      handleModalShow(
                        "Username",
                        "Filter data based on USERNAME"
                      )
                    }
                  ></span>
                </th>


                <th scope="col" className="text-left">
                  {t("Add_customer.7")}
                </th>

                <th scope="col" className="text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody id="resultdata">
              {currentPosts ? (
                currentPosts.map((d, index) => (
                  <tr key={index} id='td"+ d[i].m_id +"' className="data">
                    <td></td>
                    <td id="tb-assigned-to" className="text-left">
                      {d.id}
                    </td>
                    <td
                      id="tb-created-date"
                      className="text-left text-info hover-underline"
                      onClick={() => {
                        handleViewModal(d);
                      }}
                    >
                      {d.code}
                    </td>
                    <td id="tb-created-date" className="text-left">
                      {d.name}
                    </td>
                    <td id="tb-created-date" className="text-left">
                      {d.username}
                    </td>

                    <td id="tb-created-date" className="text-left">
                      {d.active === 1 ? "Yes" : "No"}
                    </td>
                    <td id="tb-created-date" className="text-left">
                      <AiFillEdit
                        className="text-success cursor-pointer"
                        onClick={() => {
                          handleupdate(d);
                        }}
                        size={20}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={11} className="text-center pt-20 fs-16">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="card-footer pt-2 pb-2 col-8">
          <div className="d-flex justify-content-center">
            <Pagination
              postsPerPage={linePerPage}
              totalPosts={filteredallCustomer.length}
              paginate={paginate}
            />
          </div>
        </div>
      </div>

      <Footer />
    </Main>
  );
}

export default Customer_list;
